/* Center the container */
.add {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: lightgoldenrodyellow;
}

/* Form styling */
.formSaveChit,
.formSaveName {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
  padding: 20px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Headers */
h2 {
  text-align: center;
  margin-bottom: 20px;
}

/* Label and input alignment */
.labelName {
  width: 100%;
  margin-bottom: 10px;
  font-weight: 600;
  text-align: left;
}

.input,
select {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
}

/* Button styling */
button {
  width: 100%;
  padding: 10px;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background-color: #357abd;
}

/* Ensure responsiveness */
@media (max-width: 600px) {
  .add {
    padding: 10px;
  }

  form {
    padding: 15px;
  }
}
