.border-top-row {
  border-right: 2px solid black;
  padding: 10px;
  text-align: center;
  border-bottom: 2px solid black;
}
.company_name {
  width: 80%;
}
.radioInput {
  width: 10% !important;
}
.radioblock {
  display: flex;
}
.border-top-row-last {
  padding: 15px;
  border-bottom: 2px solid black;
  width: 33%;
}
body {
  font-family: Arial, sans-serif;
  padding: 20px;
  background-color: #f7f7f7;
}
.form-container {
  border: 2px solid #000;
  width: 800px; /* Increased width */
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

.chit-container {
  border: 2px solid #000;
  width: 840px;
  margin: 20px auto;
  background-color: #fff;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}
.form-container h3,
.chit-header,
.chit-footer {
  text-align: center;
  font-weight: bold;
}
.form-row {
  margin-bottom: 10px;
}
.form-row label {
  display: inline-block;
  width: 200px;
}
.form-row input {
  padding: 5px;
  width: calc(100% - 220px);
}
.chit-header {
  font-size: 24px;
  margin-bottom: 10px;
}
.chit-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
.chit-label {
  width: 40%;
  font-weight: bold;
}
.chit-value {
  width: 58%;
  border-bottom: 1px solid #000;
  text-align: right;
}
.chit-amount-row {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.chit-amount-row div {
  width: 30%;
  border-bottom: 1px solid #000;
  padding: 5px 0;
  text-align: center;
}
.generate-chits {
  margin-top: 20px;
  text-align: center;
}
.generate-chits button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}
.chit-top-row {
  display: flex;
  width: 100%;
}
.chit-details-row {
  display: flex;
  padding-left: 1%;
  padding-right: 2%;
  justify-content: space-between;
  line-height: 2.5;
  border-bottom: 2px solid #000;
}
.chit-details-right {
  border-left: 2px solid #000;
  padding-left: 10px;
}
.chit-footer {
  margin-top: 15px;
}
.highlight {
  font-size: 26px;
  font-weight: bold;
}
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 5px;
}
table,
th,
td {
  border: 1px solid #000;
  text-align: center;
  padding: 5px;
}
.amount-in-words {
  margin-top: 10px;
  font-weight: bold;
}
.footer-columns {
  display: flex;
  justify-content: space-between;
}

.footer-right {
  margin-right: 27%;
  flex: 2;
}

.footer-left {
  border-right: 2px solid black;
  padding: 4px;
  flex: 1;
}

@media print {
  body {
    margin: 0;
    padding: 0;
  }

  .form-container,
  .chit-container {
    max-width: 1200px; /* Set max width to your desired width */
    margin: 0 auto; /* Center the container */
    box-shadow: none; /* Remove box shadow for print */
  }

  /* Center headers and footers */
  .chit-header,
  .chit-footer {
    text-align: center; /* Center text */
  }

  /* Control image size */
}
